.my-loading-page {
  .loader {
    margin: 50px auto;
    width: 64px;
    height: 64px;
    .loader-cube {
      background-color: white;
    }
  }
}

.form-generator-edit {
  .form-name {
    margin: 0px 8px 0px;
  }
  .form-slug {
    margin: 0px 8px 16px;
    color: #696969;
  }
  .form-group-prev {
    .buttons {
      display: none;
    }
  }
  .form-group-prev:hover {
    .buttons {
      display: block;
    }
  }
}

.form {
  &-groupbox {
    box-shadow: $shading-normal;
    &:hover {
      @include animation(0.3s, 0.5s, animation-slide-in-right);
      box-shadow: $shading-hover;
    }
    border: 1px solid rgba(2, 175, 160, 0.3);
    padding: 1.5em;
    margin-bottom: 1.2em;

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-bottom: 0;
    }
    &-title {
      font-size: 18px;
      margin-bottom: 1.5em;
      font-weight: 600;
      border-bottom: 1px solid rgba(2, 175, 160, 0.3);
      padding-bottom: 1em;
    }
  }
}

.item-choice {
  p {
    margin: 0;
    color: #696969;
  }
  h5 {
    margin: 0;
  }
}

.btn-block {
  &:hover {
    .text-nav {
      color: white;
    }
  }
  .text-nav {
    color: #4dbd74;
  }
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover {
    width: 300px;
    & > .nav-link {
      background-color: var(--dark-300);
      color: white !important;
    }
  }

  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background-color: var(--dark-300);
  }

  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    width: calc(100% - 50px);
    overflow: auto;
    max-height: 30vh;
    .nav-item {
      .nav-link,
      i {
        color: white !important;
      }
    }
  }

  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
    width: 100%;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    width: 100%;
  }
}

body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

i.ml-1.fa.fa-caret-down {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-20 {
  font-size: 14px !important;
}

.min-width-25 {
  min-width: 25% !important;
}

input:disabled {
  background-color: #f0f3f5 !important;
}

button {
  outline: none !important;
}

.text-giftano {
  color: var(--primary-300) !important;
  border: #02afa0 !important;
}

.btn-giftano {
  background-color: var(--primary-300) !important;
  border-color: var(--primary-300) !important;
  /* max-height: 30px; */
  /* padding: 0.2rem 0.5rem !important; */
}

.dataTables_filter {
  text-align: right !important;
}

.pagination {
  float: right !important;
}

.myTable > div > div > div > table > tbody > tr td:nth-last-child(2) {
  width: 15%;
}

.myTable > div > div > div > table > tbody > tr td {
  vertical-align: middle;
}

/* table user */
.myTableUser > div > div > div > table > tbody > tr td:first-child,
.myTableUser > div > div > div > table > thead > tr th:first-child {
  width: 5%;
  text-align: center;
}

.myTableUser > div > div > div > table > tbody > tr td:nth-last-child(1) {
  width: 8%;
}

.myTableUser > div > div > div > table > tbody > tr td:nth-last-child(2) {
  width: 17%;
}

.myTableUser > div > div > div > table > tbody > tr td {
  vertical-align: middle;
}

/* table role */
.myTableRole > div > div > div > table > tbody > tr td:nth-last-child(1) {
  width: 14% !important;
  /* background-color: #000; */
}

.myTableTag > div > div > div > table > tbody > tr td:nth-last-child(1) {
  width: 18% !important;
  text-align: center;
  /* background-color: #000; */
}

.myTableTag > div > div > div > table > tbody > tr td:nth-last-child(2) {
  width: 20%;
}

.myTableRole > div > div > div > table > tbody > tr td:first-child,
.myTableRole > div > div > div > table > thead > tr th:first-child {
  width: 5%;
  text-align: center;
}

.myTableRole > div > div > div > table > tbody > tr td:nth-last-child(1) {
  width: 12%;
  text-align: center;
}

.myTableRole > div > div > div > table > tbody > tr td:nth-last-child(2) {
  width: 20%;
}

.myTableRole > div > div > div > table > tbody > tr td {
  vertical-align: middle;
}

/* table makets */
.myTableMarket > div > div > div > table > tbody > tr td:nth-last-child(1) {
  width: 15% !important;
}

.myTableMarket > div > div > div > table > tbody > tr td:first-child,
.myTableMarket > div > div > div > table > thead > tr th:first-child {
  width: 5%;
  text-align: center;
}

.myTableMarket > div > div > div > table > tbody > tr td:nth-last-child(1) {
  width: 12%;
  text-align: center;
}

.myTableMarket > div > div > div > table > tbody > tr td:nth-last-child(2) {
  width: 20%;
}

.myTableMarket > div > div > div > table > tbody > tr td {
  vertical-align: middle;
}

tr:hover {
  cursor: pointer;
}

.myDataTable > div > div > div > div > table > tbody > tr td:nth-last-child(2) {
  width: 20%;
  text-align: center;
  background-color: #000;
}

.myTableListContactMember
  > div
  > div
  > div
  > table
  > tbody
  > tr
  td:nth-last-child(1) {
  width: 15% !important;
  text-align: center;
}

/* options */

option {
  padding: 5px 5px !important;
  background: #fff;
}

option:hover {
  padding: 5px 5px !important;
  background: #fff !important;
  border: none !important;
}

/* auto sugest */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-autosuggest__input--focused {
  outline: none;
}

.radius-0 {
  border-radius: 0 !important;
}

.border-0 {
  border: none !important;
}

.bt-1 {
  border-bottom: 1px solid #777 !important;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.row-app-info {
  padding: 5px 3px 8px;
}

.menu-item {
  margin-bottom: 0px;
  list-style-type: none;
  padding: 0px;
}

.menu-label {
  border: 1px solid rgb(185, 185, 185);
  background-color: rgb(250, 250, 250);
  padding: 2px 5px;
  margin: 0px;
  line-height: 40px;
  border-radius: 3px;
}

.menu-item ul {
  margin: 5px 0;
}
.menu-label.d-flex.justify-content-between.w-100.align-items-center {
  padding-left: 10px;
}

.card {
  /* box-shadow: none !important; */
}

.menu-item-button {
  margin-left: 20px;
  float: right;
  padding-right: 10px;
}

.nav-tabs > .hr-nav > .nav-link.active {
  color: #2f353a;
  background: #fff;
  border-color: #c8ced3;
  border-bottom-color: #c8ced3 !important;
  border-radius: 4px;
}

.hr-nav-1 > .nav-link.active {
  background: #20b2a3 !important;
  color: #fff !important;
}

.hr-nav-1.active {
  background: #20b2a3 !important;
  color: #fff !important;
  border-radius: 4px;
}

.hr-nav-1.active > button {
  color: #fff !important;
}

.nav-tabs > .hr-nav > .nav-link:hover {
  border: none;
}

.nav-hr {
  border-bottom: none !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.rte-height-200 {
  min-height: 200px;
}

.features-box {
  padding: 24px 16px 36px;
  border: 1px solid lightgray;
}

.dashboard-version {
  text-align: center;
  margin: 20px auto 50px;
}

.input-group > .css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  flex: 1;
}

.input-group > .css-2b097c-container > .css-yk16xz-control {
  border-radius: 0 !important;
  border-left: 0 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

/* .w-17{ */
/* min-width: 21% !important; */
/* } */

button.btn-primary {
  background-color: var(--primary-300);
  border-color: var(--primary-300);
}

button.btn-danger {
  background-color: var(--danger-300);
  border-color: var(--danger-300);
}

button.btn-success {
  background-color: var(--success-300);
  border-color: var(--success-300);
}
button.btn-info {
  background-color: var(--light-300);
  border-color: var(--light-300);
}

.css-79elbk {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed !important;
}

.css-df17o1 {
  color: #000 !important;
  background: rgb(216 216 216 / 70%) !important;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  /* overflow: hidden; */
}

.overflow-hidden {
  overflow: hidden;
}

.MuiTreeItem-label {
  width: 100%;
  position: relative;
  padding-left: 10px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* tags Lable */
.label-tags {
  display: inline;
}

.btn-tags-label {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1 !important;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0px !important;
}

.btn-tags-label:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-confirm-alert-overlay {
  z-index: 99999 !important;
}

.headingLable {
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}

.btn-transparent-label {
  background: none !important;
  color: #000 !important;
  border: none;
}

.col-button {
  display: none;
  visibility: visible;
}

.list-label:hover > .col-button,
label {
  display: block;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out 0s;
}

table {
  border: 1px solid #c8ced3;
}

.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.76563rem !important;
}

select.custom-select {
  margin-left: 0 !important;
}

header.app-header.navbar {
  border-bottom: 0.01rem solid #e4e5e6;
}

.card {
  /* box-shadow: none !important; */
}

// ! Variable overrides

// * Giftano Style

//  ? Font

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

//  ? Define base colors
:root {
  --primary-300: #5b25b2;
  --light-300: #ececec;
  --dark-300: #2f243a;
  --warning-300: #fdac41;
  --danger-300: #ff5b5c;
  --success-300: #4ecfa8;
}

$giftano-green: #5b25b2;
$giftano-light: #ececec;
$giftano-dark: #2f243a;
$giftano-orange: #fdac41;
$giftano-danger: #ff5b5c;
$giftano-success: #4ecfa8;
// $giftano-green: #02afa0;
// $giftano-light: #daf3f1;
// $giftano-dark: #2f243a;
// $giftano-orange: #fc9e4f;

//  ? end define base colors

$primary: $giftano-green;
$secondary: $giftano-light;
$ternary: $giftano-dark;
$quartro: $giftano-orange;
$danger: $giftano-danger;
$success: $giftano-success;

// * Animation base

$easing: 0.3s ease-in;

// * Shading

$shading-normal: 0 3px 4px 0 rgba(21, 27, 38, 0.08);
$shading-hover: 0 4px 10px 0 rgba(21, 27, 38, 0.1);
$shading-inset: inset 0 -1px 3px 0 rgba(21, 27, 38, 0.08);

//  * Radius

$radius: 0.3em;

// * margin

//  * padding

$horizontal-padding: 0 30px;
$vertical-padding: 30px 0;

//  * recolor with class
.text-giftano--green {
  color: $primary;
}
.text-giftano--light {
  color: $secondary;
}
.text-giftano--dark {
  color: $ternary;
}
.text-giftano--orange {
  color: $quartro;
}

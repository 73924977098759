// * to implement custom style for already created components
.app {
  background-color: white;
}
.app-header {
  border-bottom: none;
  // box-shadow: $shading-normal;
  .nav-item {
    button a i {
      color: $giftano-green;
    }
    button:hover a {
      color: $giftano-orange;
      i {
        color: $giftano-orange;
      }
    }
  }
  .navbar-nav {
    .dropdown-menu-right {
      top: 5px !important;
      .dropdown-item {
        i {
          color: $giftano-green;
        }
        &:hover {
          color: $giftano-orange;
          i {
            color: $giftano-orange;
          }
        }
        color: $giftano-green;
      }
    }
  }
}

.app-breadcrumb {
  margin-top: 6rem;
  @include horizontal-padding;
  padding-left: -1rem;
  padding-right: -1rem;
}

@mixin horizontal-padding {
  padding: $horizontal-padding;
}

@mixin shaded {
  border: 1px solid transparent;
  border-color: transparent !important;
  box-shadow: $shading-normal;
  &:hover {
    @include animation(0.3s, 0.5s, animation-slide-in-right);
    box-shadow: $shading-hover;
  }
}

@mixin custom-shade {
  border-color: $primary;
}

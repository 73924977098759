// Here you can add other styles

//  * Font override

body {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.fas {
  font-family: 'fontawesome' !important;
  font-style: normal !important;
}

//  * link

a {
  &:hover {
    color: $giftano-orange;
    text-decoration: none;
    transition: color 0.3s ease-in;
  }
}

// * Main Navbar

//  ! display none with CSS

.d-md-down-none.navbar-nav > li.nav-item:nth-child(n + 6),
.d-md-down-none.navbar-nav > li.nav-item:nth-child(4) {
  display: none;
}

.navbar-nav .main-submenu {
  display: none;
}

.main-navbar {
  background-color: $white;
  // padding-top: 1.5rem;
  // padding-bottom: 1.5rem;
  height: 4.5rem;
  justify-content: space-between;
  box-shadow: $shading-normal;

  .navbar-nav {
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    transition: $easing;

    .nav-item {
      color: $ternary;
      height: 100%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      transition: 0.3s ease-in;
      border-bottom: 3px solid transparent;
      &:hover {
        border-bottom: 3px solid $primary;
        > .nav-link {
          .nav-link {
            color: $primary;
          }
        }
      }
      &:last-child {
        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

// * disabled feature

.main-submenu:nth-child(3) .dropdown-menu button:nth-child(2) {
  display: none;
}
.main-submenu:nth-child(4) .dropdown-menu .dropdown-item:nth-child(5) {
  display: none;
}

// * dropdown menu

.dropdown-menu {
  box-shadow: $shading-normal;
  border: none;
  border-radius: 0 0 $radius $radius;
  top: 2.1rem;
  .dropdown-item {
    &:active {
      background-color: $secondary;
      color: $ternary;
    }
  }
}

// * Sidebar
.sidebar {
  background-color: #fff;
  .nav-item {
    transition: none;
  }
  .nav-link {
    color: $giftano-dark !important;
    // &:hover {
    //   color: #fff !important;
    // }
    &.active,
    &:hover {
      background-color: $giftano-dark !important;
      color: $giftano-orange !important;
      i {
        color: $giftano-orange !important;
      }
    }
  }
}

//  * Breadcrumb

.breadcrumb {
  box-shadow: $shading-normal;
  border-bottom: 0;
  border-radius: 0;
  transition: $easing;
  &:hover {
    box-shadow: $shading-hover;
  }
}

//  * Title

.title {
  &__name {
    text-transform: uppercase;
  }
}

//  * Button
.btn {
  border-radius: $radius;
}

.btn-primary {
  background-color: $primary !important;
  border: 1px solid transparent;
  border-color: transparent !important;
  box-shadow: $shading-normal;

  &:hover {
    @include animation(0.3s, 0.5s, animation-slide-in-right);
    border: 1px solid $primary;
    box-shadow: $shading-hover;
  }
}

.btn-secondary {
  @include shaded;
}
.btn-light {
  // @include shaded;
}
.btn-success {
  @include shaded;
}

.btn-danger {
  @include shaded;
}

.btn-giftano {
  @include shaded;
}

.btn-info {
  @include shaded;
  color: $secondary;
}

//  * Datatable

.bs-select {
  width: 100%;
}

.bs-select label {
  width: 100%;
}

.bs-select select {
  width: 18%;
  margin-left: 10px !important;
}

[data-test='datatable'] thead th {
  text-transform: uppercase;
}

.dataTables_wrapper > .row {
  margin-bottom: 12px;
}

.dataTable {
  th,
  td {
    border-top: 0;
  }
}

.datatable {
  &-status {
    font-weight: bold;
    text-transform: uppercase;
    // box-shadow: $shading-normal;
    padding: 6px 9px;
    border-radius: $radius;
    // color: $giftano-green;
  }
}

//  * Card
.card {
  border: none;
  border-radius: $radius;
  box-shadow: $shading-normal;
  transition: $easing;
  // overflow: hidden;
  &:hover {
    box-shadow: $shading-hover;
  }
  &-header {
    background-color: white;
    color: $ternary;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: $radius $radius 0 0 !important;
    box-shadow: $shading-normal;
    // border-bottom: none;
    border-color: $primary;
    padding: 1rem 1.25rem;
    // min-height: 61px;
    .widget {
      &-title {
        align-self: center;
      }
      &-head {
        min-height: 28px;
      }
    }
  }
  &-footer {
    background-color: white;
    border-top: 1px solid $giftano-green;
    padding: 1rem 1.25rem;
    button {
      margin-right: 1.5em !important;
      text-transform: uppercase;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  &-body {
    box-shadow: $shading-inset;
  }
  &-subtitle {
    margin-bottom: 15px;
    margin-top: 12px;
  }
  &-note {
    margin-bottom: 10px;
  }
}

// * Tab content

.nav-tabs .nav-link.active {
  // border-color: $primary;
  /* box-shadow: 0 0 4px 0 rgba(21, 27, 38, 0.08); */
  border-bottom-color: white;
}

.tab-content {
  border-color: $secondary;
  border-radius: $radius;
  box-shadow: 0 0 4px 0 rgba(21, 27, 38, 0.08);
}

//  * Table
.table-striped tbody tr:nth-of-type(odd) {
  // background-color: rgba(0, 0, 0, 0.02);
  background-color: #f9f9f9;
}

.table th,
.table td {
  padding: 1.25rem 0.75rem;
}

.table-hover tbody tr:hover {
  background-color: $secondary;
  transition: $easing;
}

//  * Pagination
.pagination {
  border: 1px solid $secondary;
  box-shadow: $shading-normal;
  .page-item.active {
    .page-link {
      background-color: $secondary;
      color: $ternary;
    }
  }
  .page-link {
    border-color: transparent !important;
  }
  li {
    cursor: pointer;
  }
}

//  * Modal

.modal {
  &-header {
    // background-color: $secondary;
    background-color: white;
    color: $ternary;
    text-transform: uppercase;
    border-color: $primary;
    box-shadow: $shading-normal;
  }
  &-body {
    border-bottom: 1px solid $primary;
    box-shadow: inset 0 -3px 4px 0 rgba(21, 27, 38, 0.08);
  }
  &-dialog {
    top: 6rem;
  }
  &-content {
    border-color: transparent;
  }
}

//  * Toast

.Toastify {
  &__toast-container--top-right {
    top: 8.8rem;
  }
}

.Toastify__toast--info {
  background-color: $primary;
}

.Toastify__toast {
  border-radius: $radius;
  box-shadow: $shading-normal;
}

//  * scroll to top
.scroll-to-top {
  float: right;
  //   @include shaded;
}

//  * Loader
.loader {
  width: 10px;
  height: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5.5px;
  margin-bottom: 5.5px;
  .loader-cube {
    width: 33%;
    height: 33%;
    background-color: $secondary;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }
  .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
}

.image-loader {
  background-color: $primary;
  height: 300px;
  width: 100%;
  position: absolute;
  .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
  }
}

// * Pages

//  * Login

.login-divider {
  position: relative;
  color: #bdbdbd;
  &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 42%;
    background-color: #bdbdbd;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
  }
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 42%;
    background-color: #bdbdbd;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
  }
}

.google-login {
  justify-content: center;
  border-radius: 4.2px !important;
}

//  * Home

.home {
  text-align: center;
  &__image {
    max-width: 30%;
    height: 30em;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  h3 {
    font-weight: bold;
  }
  span {
    color: $giftano-green;
    // text-decoration: underline;
  }
}

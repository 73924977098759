// * Mixins

@mixin keyframe($animation_name) {
  @-webkit-keyframes $animation_name {
    @content;
  }

  @-moz-keyframes $animation_name {
    @content;
  }

  @-o-keyframes $animation_name {
    @content;
  }

  @keyframes $animation_name {
    @content;
  }
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@mixin animation(
  $delay,
  $duration,
  $animation,
  $direction: normal,
  $fillmode: forwards
) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillmode;
  -webkit-animation-direction: $direction;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
}

// * -- Slide Animations -- //

// * Slide Out Top from Center
@include keyframe(animation-slide-out-up) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

@include keyframe(animation-slide-out-down) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 100%);
  }
}

// Slide Out Left from Center
@include keyframe(animation-slide-out-left) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

// Slide out Right from Center
@include keyframe(animation-slide-out-right) {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@include keyframe(animation-slide-in-up) {
  0% {
    transform: translate(0, 100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@include keyframe(animation-slide-in-down) {
  0% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

// Slide in Left to Center
@include keyframe(animation-slide-in-left) {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

// Slide in Right to Center
@include keyframe(animation-slide-in-right) {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

// * Implementation
.animation-slide-in-up {
  @include animation(0, 0.5s, animation-slide-in-up, ease);
}

.animation-slide-in-down {
  @include animation(0, 0.5s, animation-slide-in-down);
}

.animation-slide-in-right {
  @include animation(0, 0.5s, animation-slide-in-right, ease);
}

.animation-slide-in-left {
  @include animation(0, 0.5s, animation-slide-in-left, ease);
}

.animation-slide-out-up {
  @include animation(0, 0.5s, animation-slide-out-up, ease);
}

.animation-slide-out-down {
  @include animation(0, 0.5s, animation-slide-out-down, ease);
}

.animation-slide-out-right {
  @include animation(0, 0.5s, animation-slide-out-right, ease);
}

.animation-slide-out-left {
  @include animation(0, 0.5s, animation-slide-out-left, ease);
}
